<template>
    <b-overlay :show='showLoader'>
        <BlueBackground style="top: 2rem;"/>
        <b-row v-if="newsList && newsList.length > 0" align="center">
            <b-col v-for="news in newsList" :key="news.id" sm="12" md="6" lg="4" class="mb-2 d-flex">
                <news-card :news="news"/>
            </b-col>
        </b-row>
        <h1 v-else class="text-center pt-4">Ni podatkov za prikaz</h1>
        <pagination v-if="pagination" v-model="pagination" class="mt-1"/>
        <news-carousel class="mt-2" />
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay} from 'bootstrap-vue'
    import NewsCard from '@/views/Components/NewsCard.vue'
    import Pagination from '@/views/Components/Pagination'
    import BlueBackground from '@/views/Components/BlueBackground'
    import newsCarousel from '@/views/Components/NewsCarousel'

    export default {
        components: {Pagination, BCol, BRow, BOverlay, NewsCard, BlueBackground, newsCarousel},
        data() {
            return {
                showLoader: false,
                newsList: [],
                pagination: null,
                created: false
            }
        },
        methods: {
            getQueryParams() {
                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 12
                const searchQuery = this.$route.query.search ?? ''

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')
                const serachQueryParam = (searchQuery ? `&search=${searchQuery}&` : '')

                const p = [ 
                    pageQueryParam,
                    perPageQueryParam,
                    serachQueryParam
                ]

                return p.join('')
            },
            async loadNews() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/es/news/?${queryParams}`)
                    .then(res => {
                        this.newsList =  res.data.items ?? []
                        this.pagination = res.data.pagination
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju novic je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        async mounted() {
            await this.loadNews()
            this.created = true
        },
        watch: {
            async 'pagination.current_page'() {
                if (!this.created) return
                const currentPage = (this.pagination ? this.pagination.current_page : 1)
                if (currentPage !== 0 && this.$route.fullPath !== `/novice?page=${currentPage}`) {
                    await this.$router.replace({path: this.$route.fullPath, query:  {page: currentPage}})
                    await this.loadNews()
                }
            },
            '$route.query.search'(val) {
                if (val) {
                    this.loadNews()
                }
            }
        }
    }
</script>

<style>
#newspagination.b-pagination-pills .page-item .page-link {
  border: 2px solid #BFD42E;
  border-radius: 0 !important;
  background-color: transparent !important;
  font-weight: bold;
}
#newspagination.b-pagination-pills .page-item.active .page-link {
  background-color: #BFD42E !important;
}
.buttonPage {
  font-weight: bold;
}
.carousel-indicators li {
    background-color: black;
}
</style>
