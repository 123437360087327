<template>
    <b-card no-body class="main-card mx-auto mb-1">
        <router-link :to="{ name: 'news', params: {news_id: news.id, is_index: false }}">
            <b-img class="main-photo" fluid :src="`/api/user/v1/image/thumbnail_${news.main_photo}`"/>
        </router-link>
        <div class="container px-2 py-1 d-flex flex-column card-content">
            <router-link :to="{ name: 'news', params: {news_id: news.id, is_index: false }}">
                <div class="title">{{news.title}}</div>
            </router-link>
            <div class="mt-1 date">
                <span>{{ dayjs(news.date_added).format('DD.MM') }}</span>
                <span style="font-weight: 400;">{{ dayjs(news.date_added).format('.YYYY') }}</span>
            </div>
            <div class="mt-1 content-text" v-html="stripHTML(news.content)"></div>
            <div class="mt-auto pt-1">
                <router-link :to="{ name: 'news', params: {news_id: news.id, is_index: false }}">
                    > več o tem
                </router-link>
            </div>
        </div>
    </b-card>
</template>

<script>
    import {BCard, BImg} from 'bootstrap-vue'
    export default
        {
            components: {BCard, BImg},
            props: {
                news: {
                    type: Object,
                    required: true
                }
            },
            methods: {
                stripHTML(val) {
                    if (!val) return ''
                    return String(val).replace(/(<([^>]+)>)/gi, ' ').trim()
                }
            }
        }
</script>

<style scoped>
.card-content{
  background: #FCFCFC;
}
.title {
  font-weight: 700;
  font-size: 33px;
  line-height: 41px;
  color: #72A5D8;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.content-text {
  font-size: 17px;
  line-height: 23px;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.content-text > * {
  margin-bottom: 0 !important;
}
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
.date {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
}
.main-card {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  border: none !important;
  border-radius:0;
  box-shadow: none;
  text-align: left;
}
.container {
  flex: 1;
}
.main-photo {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
</style>